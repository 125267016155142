<template>
  <div class="terminal-data">
    <div class="title">终端数据</div>
    <div class="chat-content">
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">各终端类型使用次数</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-date-picker
                    style="width: 150px"
                    v-model="terminalTypeDay"
                    type="date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="getChat1Data">查询</el-button>
              </div>
            </div>
            <div class="chat-box" ref="chat1"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">下载数据</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-select v-model="downSelectValue" placeholder="请选择" style="width: 130px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-model="downDay"
                    :type="dateSelectType(1)"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="getChat2Data">查询</el-button>
              </div>
            </div>
            <div class="totle-data">
              <div>
                <span>下载请求数：{{ downNum }}</span>
                <span>获取下载地址：{{ downSuccessNum }}</span>
              </div>
              <span>下载文件总流量：{{ toGB(dowmFlow) }}G</span>
            </div>
            <div class="chat-box" ref="chat2">

            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">播放数据</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-select v-model="playSelectvalue" placeholder="请选择" style="width: 130px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-model="playDay"
                    :type="dateSelectType(2)"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="getChat3Data">查询</el-button>
              </div>
            </div>
            <div class="totle-data">
              <div>
                <span>请求接口总数：{{ playNum }}</span>
              </div>
              <span style="margin-right: 20px">不能播放投诉：{{ noPlayNum }}</span>
              <span>M3U8成功数：{{ playSuccessNum }}</span>
            </div>
            <div class="chat-box" ref="chat3"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">接口数据</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-select v-model="apiSelectvalue" placeholder="请选择" style="width: 130px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-model="apiDay"
                    :type="dateSelectType(3)"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="getChat4Data">查询</el-button>
              </div>
            </div>
            <div class="totle-data">
              <div>
                <span>请求接口总数：{{ requestsNum }}</span>
              </div>
              <span style="margin-right: 20px">接口报错：{{ requestsErrNum }}</span>
              <span>APP下载数：{{ appDownNum }}</span>
            </div>
            <div class="chat-box" ref="chat4"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {platformStatistics, downloadVideo, playVideo, apiCountData} from "../../api/chart"
import {convertKBToGB, getDayOfMonth, getMonthDays, getWeekDays} from "@/utils/func";

export default {
  name: "terminalData",
  data() {
    return {
      terminalTypeDay: new Date(),
      downDay: new Date(),
      playDay: new Date(),
      apiDay: new Date(),
      options: [{
        value: '1',
        label: '按日查询'
      }, {
        value: '2',
        label: '按周查询'
      }, {
        value: '3',
        label: '按月查询'
      }],
      playSelectvalue: "1",
      downSelectValue: "1",
      apiSelectvalue: "1",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      // chart2 汇总
      downNum: 0,
      downSuccessNum: 0,
      dowmFlow: 0,
      // chart3汇总
      playNum: 0,
      playSuccessNum: 0,
      noPlayNum: 0,
      // chart4汇总
      requestsNum: 0,
      requestsErrNum: 0,
      appDownNum: 0
    }
  },
  mounted() {
    this.getChat1Data()
    this.getChat2Data()
    this.getChat3Data()
    this.getChat4Data()
    this.initChart1()
    this.initChart2()
    this.initChart3()
    this.initChart4()
  },
  methods: {
    async getChat1Data() {
      try {
        const queryParams = {
          date: this.terminalTypeDay
        }
        const r = await platformStatistics(queryParams)
        if (r.code === 0) {
          const chatData = r.data.map(item => {
            return {
              value: item.count,
              name: item.deviceType
            }
          })
          console.log(chatData)
          this.myChart1.setOption({
            series: {
              data: chatData
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getChat2Data() {
      try {
        const queryParams = {
          dateType: this.downSelectValue,
          date: this.downDay
        }
        const r = await downloadVideo(queryParams)
        this.downNum = 0
        this.downSuccessNum = 0
        this.dowmFlow = 0
        if (r.code === 0) {
          const chatData = r.data
          if (this.downSelectValue === '1') {
            const time = ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
            const data = []
            for (let i = 0; i < time.length; i++) {
              const index = (i + 1) * 3
              let count = 0
              let countSuccess = 0
              let countFlow = 0
              for (let j = 0; j < chatData.length; j++) {
                if (chatData[j].hour <= index && chatData[j].hour > index - 3) {
                  if (chatData[j].downState === 1) {
                    count += chatData[j].count
                    countSuccess += chatData[j].count
                    countFlow += chatData[j].countFlow
                  }
                  if (chatData[j].downState === 0) {
                    count += chatData[j].count
                  }
                }
              }
              data.push({
                count,
                countSuccess,
                countFlow
              })
              this.downNum = this.downNum + count
              this.downSuccessNum = this.downSuccessNum + countSuccess
              this.dowmFlow = this.dowmFlow + countFlow
            }
            this.myChart2.setOption({
              xAxis: {
                data: time
              },
              series: [
                {
                  data: data.map(item => item.count)
                },
                {
                  data: data.map(item => item.countSuccess)
                },
                {
                  data: data.map(item => convertKBToGB(item.countFlow))
                }
              ]
            })
          }
          if (this.downSelectValue === '2') {
            const day = getWeekDays(this.downDay)
            const data = []
            for (let i = 0; i < day.length; i++) {
              let count = 0
              let countSuccess = 0
              let countFlow = 0
              for (let j = 0; j < chatData.length; j++) {
                const d = getDayOfMonth(chatData[j].countDate)
                if (d === day[i]) {
                  if (chatData[j].downState === 1) {
                    count += chatData[j].count
                    countSuccess += chatData[j].count
                    countFlow += chatData[j].countFlow
                  }
                  if (chatData[j].downState === 0) {
                    count += chatData[j].count
                  }
                }
              }
              data.push({
                count,
                countSuccess,
                countFlow
              })
              this.downNum = this.downNum + count
              this.downSuccessNum = this.downSuccessNum + countSuccess
              this.dowmFlow = this.dowmFlow + countFlow
            }
            this.myChart2.setOption({
              xAxis: {
                data: day
              },
              series: [
                {
                  data: data.map(item => item.count)
                },
                {
                  data: data.map(item => item.countSuccess)
                },
                {
                  data: data.map(item => convertKBToGB(item.countFlow))
                }
              ]
            })
          }
          if (this.downSelectValue === '3') {
            const day = getMonthDays(this.downDay)
            const data = []
            for (let i = 0; i < day.length; i++) {
              let count = 0
              let countSuccess = 0
              let countFlow = 0
              for (let j = 0; j < chatData.length; j++) {
                const d = getDayOfMonth(chatData[j].countDate)
                if (d === day[i]) {
                  if (chatData[j].downState === 1) {
                    count += chatData[j].count
                    countSuccess += chatData[j].count
                    countFlow += chatData[j].countFlow
                  }
                  if (chatData[j].downState === 0) {
                    count += chatData[j].count
                  }
                }
              }
              data.push({
                count,
                countSuccess,
                countFlow
              })
              this.downNum = this.downNum + count
              this.downSuccessNum = this.downSuccessNum + countSuccess
              this.dowmFlow = this.dowmFlow + countFlow
            }
            this.myChart2.setOption({
              xAxis: {
                data: day
              },
              series: [
                {
                  data: data.map(item => item.count)
                },
                {
                  data: data.map(item => item.countSuccess)
                },
                {
                  data: data.map(item => convertKBToGB(item.countFlow))
                }
              ]
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getChat3Data() {
      try {
        const queryParams = {
          dateType: this.playSelectvalue,
          date: this.playDay
        }
        const r = await playVideo(queryParams)
        this.playNum = 0
        this.playSuccessNum = 0
        this.noPlayNum = 0
        if (r.code === 0) {
          const chatData = r.data
          if (this.playSelectvalue === '1') {
            const time = ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
            const data = []
            for (let i = 0; i < time.length; i++) {
              const index = (i + 1) * 3
              let play = 0
              let playSuccess = 0
              let noPlay = 0
              for (let j = 0; j < chatData.length; j++) {
                if (chatData[j].hour <= index && chatData[j].hour > index - 3) {
                  play += chatData[j].play
                  play += chatData[j].playFail
                  playSuccess += chatData[j].play
                  noPlay += chatData[j].noPlay
                }
              }
              data.push({
                play,
                playSuccess,
                noPlay
              })
              this.playNum = this.playNum + play
              this.playSuccessNum = this.playSuccessNum + playSuccess
              this.noPlayNum = this.noPlayNum + noPlay
            }
            this.myChart3.setOption({
              xAxis: {
                data: time
              },
              series: [
                {
                  data: data.map(item => item.play)
                },
                {
                  data: data.map(item => item.playSuccess)
                },
                {
                  data: data.map(item => convertKBToGB(item.noPlay))
                }
              ]
            })
          }
          if (this.playSelectvalue === '2') {
            const day = getWeekDays(this.playDay)
            const data = []
            for (let i = 0; i < day.length; i++) {
              let play = 0
              let playSuccess = 0
              let noPlay = 0
              for (let j = 0; j < chatData.length; j++) {
                const d = getDayOfMonth(chatData[j].countDate)
                if (d === day[i]) {
                  play += chatData[j].play
                  play += chatData[j].playFail
                  playSuccess += chatData[j].play
                  noPlay += chatData[j].noPlay
                }
              }
              data.push({
                play,
                playSuccess,
                noPlay
              })
              this.playNum = this.playNum + play
              this.playSuccessNum = this.playSuccessNum + playSuccess
              this.noPlayNum = this.noPlayNum + noPlay
            }
            this.myChart3.setOption({
              xAxis: {
                data: day
              },
              series: [
                {
                  data: data.map(item => item.play)
                },
                {
                  data: data.map(item => item.playSuccess)
                },
                {
                  data: data.map(item => item.noPlay)
                }
              ]
            })
          }
          if (this.playSelectvalue === '3') {
            const day = getMonthDays(this.playDay)
            const data = []
            for (let i = 0; i < day.length; i++) {
              let play = 0
              let playSuccess = 0
              let noPlay = 0
              for (let j = 0; j < chatData.length; j++) {
                const d = getDayOfMonth(chatData[j].countDate)
                if (d === day[i]) {
                  play += chatData[j].play
                  play += chatData[j].playFail
                  playSuccess += chatData[j].play
                  noPlay += chatData[j].noPlay
                }
              }
              data.push({
                play,
                playSuccess,
                noPlay
              })
              this.playNum = this.playNum + play
              this.playSuccessNum = this.playSuccessNum + playSuccess
              this.noPlayNum = this.noPlayNum + noPlay
            }
            this.myChart3.setOption({
              xAxis: {
                data: day
              },
              series: [
                {
                  data: data.map(item => item.play)
                },
                {
                  data: data.map(item => item.playSuccess)
                },
                {
                  data: data.map(item => item.noPlay)
                }
              ]
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getChat4Data() {
      try {
        const queryParams = {
          dateType: this.apiSelectvalue,
          date: this.apiDay
        }
        const r = await apiCountData(queryParams)
        this.requestsNum = 0
        this.requestsErrNum = 0
        this.appDownNum = 0
        if (r.code === 0) {
          const chatData = r.data
          if (this.apiSelectvalue === '1') {
            const time = ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
            const data = []
            for (let i = 0; i < time.length; i++) {
              const index = (i + 1) * 3
              let requestsNum = 0
              let requestsErrNum = 0
              let appDownNum = 0
              for (let j = 0; j < chatData.length; j++) {
                if (chatData[j].hour <= index && chatData[j].hour > index - 3) {
                  requestsNum += chatData[j].requestsNum
                  requestsErrNum += chatData[j].requestsErrNum
                  appDownNum += chatData[j].appDownNum
                }
              }
              data.push({
                requestsNum,
                requestsErrNum,
                appDownNum
              })
              this.requestsNum = this.requestsNum + requestsNum
              this.requestsErrNum = this.requestsErrNum + requestsErrNum
              this.appDownNum = this.appDownNum + appDownNum
            }
            this.myChart4.setOption({
              xAxis: {
                data: time
              },
              series: [
                {
                  data: data.map(item => item.requestsNum)
                },
                {
                  data: data.map(item => item.requestsErrNum)
                },
                {
                  data: data.map(item => convertKBToGB(item.appDownNum))
                }
              ]
            })
          }
          if (this.apiSelectvalue === '2') {
            const day = getWeekDays(this.apiDay)
            const data = []
            for (let i = 0; i < day.length; i++) {
              let requestsNum = 0
              let requestsErrNum = 0
              let appDownNum = 0
              for (let j = 0; j < chatData.length; j++) {
                const d = getDayOfMonth(chatData[j].countDate)
                if (d === day[i]) {
                  requestsNum += chatData[j].requestsNum
                  requestsErrNum += chatData[j].requestsErrNum
                  appDownNum += chatData[j].appDownNum
                }
              }
              data.push({
                requestsNum,
                requestsErrNum,
                appDownNum
              })
              this.requestsNum = this.requestsNum + requestsNum
              this.requestsErrNum = this.requestsErrNum + requestsErrNum
              this.appDownNum = this.appDownNum + appDownNum
            }
            this.myChart4.setOption({
              xAxis: {
                data: day
              },
              series: [
                {
                  data: data.map(item => item.requestsNum)
                },
                {
                  data: data.map(item => item.requestsErrNum)
                },
                {
                  data: data.map(item => convertKBToGB(item.appDownNum))
                }
              ]
            })
          }
          if (this.apiSelectvalue === '3') {
            const day = getMonthDays(this.apiDay)
            const data = []
            for (let i = 0; i < day.length; i++) {
              let requestsNum = 0
              let requestsErrNum = 0
              let appDownNum = 0
              for (let j = 0; j < chatData.length; j++) {
                const d = getDayOfMonth(chatData[j].countDate)
                if (d === day[i]) {
                  requestsNum += chatData[j].requestsNum
                  requestsErrNum += chatData[j].requestsErrNum
                  appDownNum += chatData[j].appDownNum
                }
              }
              data.push({
                requestsNum,
                requestsErrNum,
                appDownNum
              })
              this.requestsNum = this.requestsNum + requestsNum
              this.requestsErrNum = this.requestsErrNum + requestsErrNum
              this.appDownNum = this.appDownNum + appDownNum
            }
            this.myChart4.setOption({
              xAxis: {
                data: day
              },
              series: [
                {
                  data: data.map(item => item.requestsNum)
                },
                {
                  data: data.map(item => item.requestsErrNum)
                },
                {
                  data: data.map(item => convertKBToGB(item.appDownNum))
                }
              ]
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    dateSelectType(index) {
      let switchValue = null
      if (index === 1) {
        switchValue = this.downSelectValue
      }
      if (index === 2) {
        switchValue = this.playSelectvalue
      }
      if (index === 3) {
        switchValue = this.apiSelectvalue
      }
      switch (switchValue) {
        case "1":
          return 'date'
        case "2":
          return 'week'
        case "3":
          return 'month'
        default:
          return 'date'
      }
    },
    initChart1() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart1 = this.$echarts.init(this.$refs.chat1);
      // 绘制图表
      this.myChart1.setOption({
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            saveAsImage: {show: true}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: '20',
        },
        series: [
          {
            name: '登录次数',
            type: 'pie',
            radius: '50%',
            data: [
              {value: 1048, name: 'Phone'},
              {value: 735, name: 'WEB'},
              {value: 580, name: 'PC'}
            ],
            label: {
              formatter: '{b}:{c}次\n占比{d}%',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      });
    },
    initChart2() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = this.$echarts.init(this.$refs.chat2);
      // 绘制图表
      this.myChart2.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['下载请求数', '获得下载地址', '下载总流量'],
          orient: 'horizontal',
          bottom: '10',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          top: "25%",
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            saveAsImage: {show: true}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',
            name: '下载流量',
            axisLabel: {
              formatter: '{value}G'
            }
          }
        ],
        series: [
          {
            name: '下载请求数',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 210, 100]
          },
          {
            name: '获得下载地址',
            type: 'line',
            data: [220, 182, 191, 234, 290, 330, 310, 500]
          },
          {
            name: '下载总流量',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' G';
              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 600]
          },
        ]
      });
    },
    initChart3() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart3 = this.$echarts.init(this.$refs.chat3);
      // 绘制图表
      this.myChart3.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['M3U8请求数', 'M3U8成功数', '不能播放投诉'],
          orient: 'horizontal',
          bottom: '10',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          top: "25%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
        },
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',

          }
        ],
        series: [
          {
            name: 'M3U8请求数',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 210, 100]
          },
          {
            name: 'M3U8成功数',
            type: 'line',
            data: [220, 182, 191, 234, 290, 330, 310, 500]
          },
          {
            name: '不能播放投诉',
            type: 'line',
            data: [150, 232, 201, 154, 190, 330, 410, 600]
          },
        ]
      });
    },
    initChart4() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart4 = this.$echarts.init(this.$refs.chat4);
      // 绘制图表
      this.myChart4.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['接口请求数', '接口请求报错数', 'APP下载数'],
          orient: 'horizontal',
          bottom: '10',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          top: "25%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
        },
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',

          }
        ],
        series: [
          {
            name: '接口请求数',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 210, 100]
          },
          {
            name: '接口请求报错数',
            type: 'line',
            data: [220, 182, 191, 234, 290, 330, 310, 500]
          },
          {
            name: 'APP下载数',
            type: 'line',
            data: [150, 232, 201, 154, 190, 330, 410, 600]
          },
        ]
      });
    },
    toGB(size) {
      return convertKBToGB(size)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.6rem;
  color: #333;
  padding-bottom: 10px;
  font-weight: bold;
  padding-left: 1.4rem;
}

.chat-content {
  box-sizing: border-box;
}

.chat-item {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: .7rem;
  position: relative;
}

.chat-title {
  font-size: 1.29rem;
  color: #333333;
  margin-bottom: 1.4rem;
}

.chat-box {
  background: #f0eeee;
  min-height: 26rem;
}

.totle-data {
  position: absolute;
  top: 160px;
  left: 0;
  z-index: 999;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;

  div {
    flex: 1;

    span {
      margin-right: 20px;
    }
  }

  span {
    color: #333;
  }
}

.search-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;

  .form-label {
    padding-right: 1rem;
  }
}
</style>
